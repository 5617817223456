<template>
    <div class="user-collect">
        <el-card class="box-card list-card"  shadow="never">
            <div slot="header" class="clearfix">
                <span>浏览记录</span>
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <div class="list-item" v-for="item in listData.data" v-show=" listData.data.length > 0" @click="goDetail(item)">
                <div class="left">
                    <div class="title" v-html="`${item.title}`"></div>
<!--                    <div class="time">-->
<!--                        支付金额： {{item.payamount}} 元-->
<!--                    </div>-->
                    <div class="time">
                        浏览时间： {{$formatDate(item.createtime, true)}}
                    </div>
                </div>
                <div class="center">
                    <div class="labels">
                        <div>{{item.city}}</div>
                    </div>
                </div>
                <div class="right" >
                    <el-button type="text"  @click="goDetail(item)">查看详情</el-button>
<!--                    <el-button type="text" size="small" @click.stop.native="goRead(item)">在线阅读</el-button>-->
<!--                    <el-button type="text" >|</el-button>-->
<!--                    <el-button type="text"  @click.stop.native="down(item)" size="small" >下载文档</el-button>-->
<!--                    <el-button type="text" >|</el-button>-->
<!--                    <el-button type="text"  @click.stop.native="down(item)" size="small" >取消收藏</el-button>-->
                </div>
            </div>
            <el-empty description="暂无数据" :image-size="100" v-show=" listData.data.length === 0"></el-empty>
        </el-card>
        <el-pagination
                style="text-align: center;margin-top: 10px"
                background
                layout="prev, pager, next"
                :current-page="listData.current_page"
                :page-size="Number(listData.per_page)"
                :page-count="listData.last_page"
                @current-change="currentChange"
                :total="listData.total">
        </el-pagination>
    </div>
</template>

<script>
    import {UserService} from "../../../api/user";

    export default {
        name: "user-order-index",
        data() {
            return {
                listData: {
                    current_page: 1,
                    isRecommend: false,
                    last_page: 1,
                    data: [],
                    per_page: 1,
                    total: 0
                },
                formInline: {
                    page: 1,
                    pageSize: 50
                },
            }
        },
        methods: {
            goRead(item) {
                this.$router.push({
                    name: 'detailRead',
                    query: {
                        id: item.archives_id
                    }
                })
            },
            goDetail(item) {
                // // console.log('item', item)
                this.$router.push({
                    query: {
                        id: item.archives_id
                    },
                    name: 'detail'
                })
            },
            currentChange(page) {
                this.formInline.page = page
                this.getList()
            },
            down(item) {
                this.$pay.show({
                    id: item.archives_id,
                    type: 'down'
                })
            },
            getList() {
                UserService.record(this.formInline).then(res => {
                    this.listData = res.data
                })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style scoped lang="scss">
    .user-collect{
    }

</style>